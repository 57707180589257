<template>
  <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="unselectable">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5335 0.800049H2.1335C1.1435 0.800049 0.333496 1.61005 0.333496 2.60005V13.4C0.333496 14.39 1.1435 15.2 2.1335 15.2H16.5335C17.5235 15.2 18.3335 14.39 18.3335 13.4V2.60005C18.3335 1.61005 17.5235 0.800049 16.5335 0.800049ZM16.5335 4.40005L9.3335 8.90005L2.1335 4.40005V2.60005L9.3335 7.10005L16.5335 2.60005V4.40005Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'IcoEmail',

  props: {
    color: {type: String, default: '#758289'}
  }
}
</script>
